import { Component, OnInit } from '@angular/core';
import {CookieService} from "../../_services/cookie.service";

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.sass']
})
export class CookieBannerComponent implements OnInit {

  constructor(
    private _cookieService: CookieService
  ) { }

  ngOnInit() {
  }

  agree() {
    this._cookieService.agree();

    /*setTimeout(() => {
      window.location.reload();
    }, 500);*/
  }
}
