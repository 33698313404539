import {Component, OnDestroy, OnInit} from '@angular/core';
import {CookieService} from "../_services/cookie.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.sass']
})
export class ContentComponent implements OnInit, OnDestroy {

  private _cookieConsentSubscription: Subscription;
  public cookieConsent = true;

  constructor(
    private _cookieService: CookieService
  ) { }

  ngOnInit() {
    this._cookieConsentSubscription = this._cookieService.cookieConsent.subscribe(cc => this.cookieConsent = cc);
  }

  ngOnDestroy() {
    this._cookieConsentSubscription.unsubscribe();
  }

}
