import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable()
export class CookieService {

  public cookieConsent: BehaviorSubject<boolean>;

  private _localStorageKey = 'cookieConsent';
  private _localStorageYes = 'yes';
  private _localStorageNo = 'no';

  constructor() {
    this.cookieConsent = new BehaviorSubject<boolean>(false);
    this.getCookiesConsent();
  }

  getCookiesConsent() {
    const cc = localStorage.getItem(this._localStorageKey);
    if (cc === null) {
      localStorage.setItem(this._localStorageKey, this._localStorageNo);
    }

    const cookieConsent = localStorage.getItem(this._localStorageKey) === this._localStorageYes;
    this.cookieConsent.next(cookieConsent);

    return cookieConsent;
  }

  agree() {
    localStorage.setItem(this._localStorageKey, this._localStorageYes);
    this.getCookiesConsent();
  }

  disagree() {
    localStorage.setItem(this._localStorageKey, this._localStorageNo);
    this.getCookiesConsent();
  }

}
