import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-est',
  templateUrl: './est.component.html',
  styleUrls: ['./est.component.sass']
})
export class EstComponent implements OnInit {

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'EST',
      'La Corelli EST Electric String Trio');
  }

}
