import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Poll } from '../../_models/poll.model';
import { PollService } from '../../_services/poll.service';

@Component({
  selector: 'app-melologic',
  templateUrl: './melologic.component.html',
  styleUrls: ['./melologic.component.sass'],
})
export class MelologicComponent implements OnInit {
  public poll: Poll | null = null;
  public answered: boolean = false;
  public voting: boolean = false;

  protected pollUuids: string[] = [];

  constructor(
    protected pollService: PollService,
  ) {

    const polls = localStorage.getItem('polls') || '[]';
    this.pollUuids = JSON.parse(polls);
  }

  ngOnInit() {
    this.loadPoll();
    setInterval(() => {
      this.loadPoll();
    }, 5000);
  }

  protected loadPoll() {
    this.pollService.get().pipe(
      tap((poll) => {
        this.poll = poll;
        if (this.pollUuids.includes(this.poll.uuid)) {
          this.answered = true;
        } else {
          this.answered = false;
        }
      }),
      catchError(() => {
        this.poll = null;
        return of(null);
      }),
    ).subscribe();
  }

  public vote(value: number): void {
    this.voting = true;
    this.pollService.vote(value).pipe(
      tap(() => {
        this.answered = true;
        this.pollUuids.push(this.poll.uuid);
        localStorage.setItem('polls', JSON.stringify(this.pollUuids));
      }),
      finalize(() => this.voting = false),
    ).subscribe();
  }
}
