import {Deserializable} from "../_interfaces/deserializable.interface";

export class Tag implements Deserializable {
  uuid: string;
  name: string;
  name_slug: string;


  deserialize(input: any) {
    this.uuid = input.uuid;
    this.name = input.name;
    this.name_slug = input.name_slug;

    return this;
  }
}
