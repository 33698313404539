import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { HomeComponent } from "./content/home/home.component";
import { MelologicComponent } from './content/melologic/melologic.component';
import { PageNotFoundComponent } from "./content/page-not-found/page-not-found.component";
import { EventComponent } from "./content/event/event.component";
import { LacorelliComponent } from "./content/lacorelli/lacorelli.component";
import { EnsembleTempoPrimoComponent } from "./content/ensemble-tempo-primo/ensemble-tempo-primo.component";
import { EventsComponent } from "./content/events/events.component";
import { AmministrazioneTrasparenteComponent } from "./content/amministrazione-trasparente/amministrazione-trasparente.component";
import { OrchestraArcangeloCorelliComponent } from "./content/orchestra-arcangelo-corelli/orchestra-arcangelo-corelli.component";
import { PopFusionOrchestraComponent } from "./content/pop-fusion-orchestra/pop-fusion-orchestra.component";
import { YoubrassEnsembleComponent } from "./content/youbrass-ensemble/youbrass-ensemble.component";
import { EstComponent } from "./content/est/est.component";
import {ContactComponent} from "./content/contact/contact.component";
import {PolicyComponent} from "./content/policy/policy.component";
import { TrasparenzaComponent } from './content/trasparenza/trasparenza.component';
import {DelittoComponent} from "./content/delitto/delitto.component";

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'lacorelli',
    component: LacorelliComponent,
  },
  {
    path: 'ensemble-tempo-primo',
    component: EnsembleTempoPrimoComponent,
  },
  {
    path: 'youbrass-ensemble',
    component: YoubrassEnsembleComponent,
  },
  {
    path: 'est',
    component: EstComponent,
  },
  {
    path: 'orchestra-arcangelo-corelli',
    component: OrchestraArcangeloCorelliComponent,
  },
  {
    path: 'pop-fusion-orchestra',
    component: PopFusionOrchestraComponent,
  },
  {
    path: 'amministrazione-trasparente',
    component: AmministrazioneTrasparenteComponent,
  },
  {
    path: 'trasparenza',
    component: TrasparenzaComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'event/:uuid/:title',
    component: EventComponent,
  },
  {
    path: 'events',
    component: EventsComponent,
  },
  {
    path: 'events/:query/:resource_uuid/:resource_name_slug',
    component: EventsComponent,
  },
  {
    path: 'events/:query',
    component: EventsComponent,
  },
  {
    path: 'event/:uuid',
    component: EventComponent,
  },
  {
    path: 'policy',
    component: PolicyComponent,
  },
  {
    path: 'melologic',
    component: MelologicComponent,
  },
  {
    path: 'delitto',
    component: DelittoComponent,
  },
  {
    path: 'vota',
    component: MelologicComponent,
  },
  {
    path: 'vote',
    component: MelologicComponent,
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];

const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

export const Routing = RouterModule.forRoot(appRoutes, routingConfiguration);
