import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Tag } from "../_models/tag.model";

@Injectable()
export class TagService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  get(uuid: string) {
    return this._http.get<Tag>(`api/tags/${uuid}`)
      .pipe(map(data => new Tag().deserialize(data)));
  }
}
