import {Deserializable} from "../_interfaces/deserializable.interface";
import {LatLng} from "leaflet";

export class Venue implements Deserializable {
  uuid: string;
  name: string;
  name_slug: string;
  address: string;
  city: string;
  lat: number;
  lon: number;

  coords: LatLng;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.name = input.name;
    this.name_slug = input.name_slug;
    this.address = input.address;
    this.city = input.city;

    if (input.coords) {
      this.coords = input.coords;
      this.lat = this.coords.lat;
      this.lon = this.coords.lng;
    } else {
      this.lat = input.lat ? input.lat : 0;
      this.lon = input.lon ? input.lon : 0;
      this.coords = new LatLng(this.lat, this.lon);
    }

    return this;
  }
}
