import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-ensemble-tempo-primo',
  templateUrl: './ensemble-tempo-primo.component.html',
  styleUrls: ['./ensemble-tempo-primo.component.sass']
})
export class EnsembleTempoPrimoComponent implements OnInit {

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'Ensemble La Corelli',
      'La Corelli Ensemble La Corelli');
  }

}
