import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NewsletterService {

  constructor(
    private _http: HttpClient
  ) {}

  subscribe(email, fullname) {
    return this._http.post<any>(`api/newsletter/subscribe`, {
      email: email,
      fullName: fullname
    });
  }

}
