import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.sass']
})
export class PolicyComponent implements OnInit {

  showing = 'privacy';

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'Informativa Privacy e Cookies',
      'La Corelli Informativa Privacy e Cookies');
  }

  show(page: string) {
    this.showing = page;
  }

}
