import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event } from '../_models/event.model'

import {map, tap} from 'rxjs/operators';
import {BehaviorSubject} from "rxjs";
import { InstagramPost } from "../_models/instagramPost.model";

@Injectable()
export class InstagramService {

  constructor(private _http: HttpClient) {

  }

  getAll() {
    return this._http.get<any[]>(`api/instagram`)
        .pipe(map(data => data.map(d => new InstagramPost().deserialize(d))));
  }

}
