import { Component, OnInit } from '@angular/core';
import { Event } from "../../_models/event.model";
import { EventService } from "../../_services/event.service";

declare var calendar: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.sass']
})
export class CalendarComponent implements OnInit {

  private _events: Event[] = [];
  public selectedDayEvents: Event[] = [];

  constructor(
    private _eventService: EventService
  ) {

  }

  ngOnInit() {
    this._eventService.getAll().subscribe(events => {
      this._events = events;
      this.initCalendar(this._events);
    });
  }

  initCalendar(events) {
    let settings = {
      context: this,
      eventClick: this.eventClick,
      displayNextPrevDays: false
    };
    calendar(document.getElementById('calendar'), events, settings);
  }

  eventClick(date: Date) {
    this.selectedDayEvents = this._events.filter((e: Event) => {
      const evDate: Date = e.date_moment.toDate();
      const toDate: Date = date;
      return evDate.getFullYear() === toDate.getFullYear() && evDate.getMonth() === toDate.getMonth() && evDate.getDate() === toDate.getDate();
    });
  }

}
