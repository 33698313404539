import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-orchestra-arcangelo-corelli',
  templateUrl: './orchestra-arcangelo-corelli.component.html',
  styleUrls: ['./orchestra-arcangelo-corelli.component.sass']
})
export class OrchestraArcangeloCorelliComponent implements OnInit {

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'Orchestra La Corelli',
      'La Corelli Orchestra La Corelli');
  }

}
