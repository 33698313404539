 import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Poll } from '../_models/poll.model';

@Injectable()
export class PollService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  get() {
    return this._http.get<Poll>(`api/polls/latest`)
      .pipe(map(data => new Poll().deserialize(data)));
  }

  vote(value: number) {
    return this._http.post<Poll>(`api/polls/vote`, {vote: value});
  }
}
