import {Deserializable} from "../_interfaces/deserializable.interface";

export class Poll implements Deserializable {
  uuid: string;
  question: string;
  option_0: string;
  option_1: string;
  option_0_total: number;
  option_1_total: number;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.question = input.question.question;
    this.option_0 = input.question.option_0;
    this.option_1 = input.question.option_1;

    this.option_0_total = input.option_0_total;
    this.option_1_total = input.option_1_total;

    return this;
  }
}
