import { Component, OnInit } from '@angular/core';
import { SeoService } from '../../_services/seo.service';

@Component({
  selector: 'app-trasparenza',
  templateUrl: './trasparenza.component.html',
  styleUrls: ['./trasparenza.component.sass']
})
export class TrasparenzaComponent implements OnInit {

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'Trasparenza',
      'La Corelli Trasparenza, Obblighi di trasparenza previsti dalla Legge n. 124 del 2017');
  }

}
