import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Venue } from "../_models/venue.model";

@Injectable()
export class VenueService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  get(uuid: string) {
    return this._http.get<Venue>(`api/venues/${uuid}`)
      .pipe(map(data => new Venue().deserialize(data)));
  }
}
