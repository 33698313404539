import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

export enum Type {
  page,
  event
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private _templatePlaceholder = '#####';
  private _titleTemplate = '##### - La Corelli';

  private _defaultDescription = 'La Corelli';
  private _defaultImage = 'https://www.lacorelli.it/assets/logo_scritta_200.png';

  public jsonLd: BehaviorSubject<Object>;
  private _jsonLd: Object;

  constructor(
    private _meta: Meta,
    private _title: Title,
    private _router: Router
  ) {
    this._jsonLd = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      'name': 'La Corelli',
      'url': 'https://www.lacorelli.it'
    };
    this.jsonLd = new BehaviorSubject<Object>(this._jsonLd);
  }

  setPage(title: string, description: string = null, image: string = null) {

    description = description ? description : this._defaultDescription;
    image = image ? image : this._defaultImage;

    this._updateMeta(title, description, image, Type.page);

    this.updateJsonLd({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      'name': this._getFullTitle(title),
      'url': this._getCurrentUrl(),
    });
  }

  updateJsonLd(jsonLd: Object) {
    this._jsonLd = jsonLd;
    this.jsonLd.next(this._jsonLd);
  }

  private _updateMeta(title: string, description: string, image: string = null, type: Type = null) {
    this._title.setTitle(this._getFullTitle(title));
    this._meta.updateTag({ name: 'description', content: description });

    this._updateTwitterMeta(title, description, image, type);
    this._updateOpenGraphMeta(title, description, image, type);
  }

  private _updateTwitterMeta(title: string, description: string, image: string, type: Type) {
    this._meta.updateTag({ name: 'twitter:card', content: 'summary' }, `name='twitter:card'`);
    this._meta.updateTag({ name: 'twitter:title', content: title }, `name='twitter:title'`);
    this._meta.updateTag({ name: 'twitter:description', content: description }, `name='twitter:description'`);
    this._meta.updateTag({ name: 'twitter:image', content: image }, `name='twitter:image'`);
  }

  private _updateOpenGraphMeta(title: string, description: string, image: string, type: Type) {

    if (type == Type.page) {
      this._meta.updateTag({ property: 'og:type', content: 'website' }, `property='og:type'`);
      //this._meta.updateTag({ property: 'og:video', content: '' }, `property='og:video'`);
    }

    this._meta.updateTag({ property: 'og:url', content: this._getCurrentUrl() }, `property='og:url'`);
    this._meta.updateTag({ property: 'og:title', content: title }, `property='og:title'`);
    this._meta.updateTag({ property: 'og:description', content: description }, `property='og:description'`);
    this._meta.updateTag({ property: 'og:image', content: image }, `property='og:image'`);

  }

  private _getFullTitle(title: string) {
    return this._titleTemplate.replace(this._templatePlaceholder, title);
  }

  private _getCurrentUrl() {
    return window.location.href;
  }
}

