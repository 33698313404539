import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event } from '../_models/event.model'

import { map, shareReplay, tap } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class EventService extends BehaviorSubject<any[]> {

  private _allQuery: Observable<Event[]>;
  private _allPastQuery: Observable<Event[]>;

  constructor(private _http: HttpClient) {
    super([]);
    this._allQuery = this._http.get<Event[]>(`api/events`)
      .pipe(map(data => data.map(d => new Event().deserialize(d))), shareReplay());
    this._allPastQuery = this._http.get<Event[]>(`api/events/past`)
      .pipe(map(data => data.map(d => new Event().deserialize(d))), shareReplay());
  }

  getAll(): Observable<Event[]> {
    return this._allQuery;
  }

  getAllPast(): Observable<Event[]> {
    return this._allPastQuery;
  }

  get(uuid: string) {
    return this._http.get<Event>(`api/events/${uuid}`)
      .pipe(map(event => new Event().deserialize(event)));
  }
}
