import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-amministrazione-trasparente',
  templateUrl: './amministrazione-trasparente.component.html',
  styleUrls: ['./amministrazione-trasparente.component.sass']
})
export class AmministrazioneTrasparenteComponent implements OnInit {

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'Amministrazione Trasparente',
      'La Corelli Amministrazione Trasparente, Visura camerale, Statuto, Atto di fondazione');
  }

}
