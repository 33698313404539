import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {InstagramService} from "../../_services/instagram.service";
import {SeoService} from "../../_services/seo.service";
import {EventService} from "../../_services/event.service";
import {NewsletterService} from "../../_services/newsletter.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {

  public subscribeForm: FormGroup;
  public subscribeMessage = '';

  constructor(
    private _formBuilder: FormBuilder,
    private _newsletterService: NewsletterService,
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this.buildForm();
    this._seoService.setPage('Contatti', 'Contatti La Corelli, info@lacorelli.it, Ufficio Stampa, Direttore Generale, Direttore Artistico, Presidente');
  }

  buildForm() {
    this.subscribeForm = this._formBuilder.group({
      email: ['', Validators.required],
      fullName: [''],
    });
  }

  subscribe() {
    this.subscribeMessage = '';
    const formValue = this.subscribeForm.value;

    if (this.subscribeForm.valid) {
      const formValue = this.subscribeForm.value;
      this._newsletterService.subscribe(formValue.email, formValue.fullName).subscribe((response) => {
        console.log(response);
        if (response.status != "subscribed") {
          this.subscribeMessage = 'Si è verificato un errore! (' + response.title + ')';
        } else {
          this.subscribeMessage = 'Iscrizione effettuata!';
        }
      }, (error) => {
        console.log(error);
        this.subscribeMessage = 'Si è verificato un errore!';
      });
    }
  }

}
