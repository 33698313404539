import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-youbrass-ensemble',
  templateUrl: './youbrass-ensemble.component.html',
  styleUrls: ['./youbrass-ensemble.component.sass']
})
export class YoubrassEnsembleComponent implements OnInit {

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'Youbrass Ensemble',
      'La Corelli Youbrass Ensemble');
  }

}
