import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-lacorelli',
  templateUrl: './lacorelli.component.html',
  styleUrls: ['./lacorelli.component.sass']
})
export class LacorelliComponent implements OnInit {

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'Chi siamo',
      'La Corelli chi siamo');
  }

}
