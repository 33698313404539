import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from "rxjs";
import { Poll } from '../_models/poll.model';

@Injectable()
export class AnswerService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  vote(fullName: string, contact: string, answer: string, reason: string) {
    return this._http.post<Poll>(`api/answers/vote`, {
      full_name: fullName,
      contact: contact,
      answer: answer,
      reason: reason,
    });
  }
}
