import { Component, OnInit } from '@angular/core';
import { EventService } from "../../_services/event.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Tag } from "../../_models/tag.model";
import { Venue } from "../../_models/venue.model";
import { TagService } from "../../_services/tag.service";
import { VenueService } from "../../_services/venue.service";
import { Event } from "../../_models/event.model";
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.sass']
})
export class EventsComponent implements OnInit {

  public events: Event[];
  public pastEvents: Event[];

  public pastYear: number|null = null;
  public pastYears: number[] = [];

  public view: 'all'|'tag'|'venue' = 'all';
  public viewingPastEvents: boolean = false;
  public tag: Tag;
  public venue: Venue;

  private _events: Event[];
  private _pastEvents: Event[];

  private _query: string;
  private _resource_uuid: string;
  private _resource_name_slug: string;

  constructor(
    private _eventService: EventService,
    private _tagService: TagService,
    private _venueService: VenueService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _seoService: SeoService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this._query = null;
      this._resource_uuid = null;
      this._resource_name_slug = null;
      if (params['query']) {
        this._query = params['query'];
      }
      if (params['resource_uuid']) {
        this._resource_uuid = params['resource_uuid'];
      }
      if (params['resource_name_slug']) {
        this._resource_name_slug = params['resource_name_slug'];
      }
      this._activateView();
    });
  }

  ngOnInit() {
    this._seoService.setPage("Eventi", "La Corelli eventi");
  }

  stop(event) {
    event.stopPropagation();
  }

  filterYear(year: number | null): void {
    this.pastYear = year;

    if (year === null) {
      this.pastEvents = this._pastEvents;
    } else {
      this.pastEvents = this._pastEvents.filter(event => event.date_moment.year() === year);
    }
  }

  private _activateView() {
    this._eventService.getAll().subscribe(events => {
      this._events = events;
      this._eventService.getAllPast().subscribe(events => {
        this._pastEvents = events;

        for (const event of events) {
          const year = event.date_moment.year();
          if (this.pastYears.includes(year)) continue;

          this.pastYears.push(year);
        }

        this.pastYears.sort();

        if (this._query == 'tag' && this._resource_uuid && this._resource_name_slug) {
          this._viewTag();
        } else if (this._query == 'venue' && this._resource_uuid && this._resource_name_slug) {
          this._viewVenue();
        } else {
          this._viewAll();
        }

      });
    });
  }

  private _viewTag() {
    this.view = 'tag';
    this._tagService.get(this._resource_uuid).subscribe((tag: Tag) => {
      this.tag = tag;
      this.events = this._events.filter((e: Event) => !!e.tags.find((t: Tag) => t.uuid == this.tag.uuid));
      this.pastEvents = this._pastEvents.filter((e: Event) => !!e.tags.find((t: Tag) => t.uuid == this.tag.uuid));
    }, () => {
      this._router.navigate(['/not-found'], {replaceUrl: true})
    });
  }

  private _viewVenue() {
    this.view = 'venue';
    this._venueService.get(this._resource_uuid).subscribe((venue: Venue) => {
      this.venue = venue;
      this.events = this._events.filter((e: Event) => e.venue && e.venue.uuid == this.venue.uuid);
      this.pastEvents = this._pastEvents.filter((e: Event) => e.venue && e.venue.uuid == this.venue.uuid);
    }, () => {
      this._router.navigate(['/not-found'], {replaceUrl: true})
    });
  }

  private _viewAll() {
    this.events = this._events;
    this.pastEvents = this._pastEvents;
  }

}
