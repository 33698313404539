import { Component } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import {AnswerService} from "../../_services/answer.service";

@Component({
  selector: 'app-delitto',
  templateUrl: './delitto.component.html',
  styleUrls: ['./delitto.component.sass'],
})
export class DelittoComponent {
  public answered: boolean = false;
  public voting: boolean = false;

  public fullName: string = '';
  public contact: string = '';
  public answer: string | null = null;
  public reason: string = '';

  constructor(
    protected answerService: AnswerService,
  ) {
    const answerLocalStorage = localStorage.getItem('answer') || '';
    if (answerLocalStorage) {
      this.answered = true;
    }
  }

  public select(answer: string): void {
    this.answer = answer;
  }

  public vote(): void {
    if (!this.infoProvided || !this.canSubmit) return;

    this.voting = true;
    this.answerService.vote(this.fullName, this.contact, this.answer, this.reason).pipe(
      tap(() => {
        this.answered = true;
        localStorage.setItem('answer', this.answer);
      }),
      finalize(() => this.voting = false),
    ).subscribe();
  }

  public get infoProvided(): boolean {
    return this.fullName.trim() !== '' && this.contact.trim() !== '';
  }

  public get canSubmit(): boolean {
    return this.reason.trim() !== '' && this.answer !== null;
  }
}
