import {Component, OnDestroy, OnInit} from '@angular/core';

import * as moment from 'moment';
import { SeoService } from "./_services/seo.service";
import { NavigationEnd, Router } from "@angular/router";
import {Subscription} from "rxjs";

declare let ga: any;
declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {

  public jsonLd: Object;
  private _jsonLdSubscription: Subscription;

  constructor(
    private _seoService: SeoService,
    private _router: Router,
  ) {
    this._seoService.setPage("Welcome");
    moment.locale('it');

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        //document.getElementsByTagName('body')[0].blur();
        /*
        if (typeof ga !== 'undefined') {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview', event.urlAfterRedirects);
        }
        */
        if (typeof gtag !== 'undefined') {
          gtag('config', 'UA-51532435-1', {'page_path': event.urlAfterRedirects});
        }
      }
    });
  }

  ngOnInit() {
    this._jsonLdSubscription = this._seoService.jsonLd.subscribe((jsonLd) => this.jsonLd = jsonLd);
  }

  ngOnDestroy() {
    this._jsonLdSubscription.unsubscribe();
  }
}
