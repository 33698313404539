import {Deserializable} from "../_interfaces/deserializable.interface";

export class InstagramPost implements Deserializable {
  image_url: string;
  url: string;

  deserialize(input: any) {
    this.image_url = input.image_url;
    this.url = input.url;

    return this;
  }
}
