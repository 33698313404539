import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  public menuActive = false;

  constructor(
    private _router: Router
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menuActive = false;
      }
    });
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.menuActive = !this.menuActive;
  }

}
