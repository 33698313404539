import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../_services/seo.service";

@Component({
  selector: 'app-pop-fusion-orchestra',
  templateUrl: './pop-fusion-orchestra.component.html',
  styleUrls: ['./pop-fusion-orchestra.component.sass']
})
export class PopFusionOrchestraComponent implements OnInit {

  constructor(
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage(
      'Pop Fusion Orchestra',
      'La Corelli Pop Fusion Orchestra');
  }

}
