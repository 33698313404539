import { Component, OnInit, ViewChild } from "@angular/core";
import {EventService} from '../../_services/event.service';
import { Event } from "../../_models/event.model";
import { SwiperComponent, SwiperDirective } from "ngx-swiper-wrapper";
import { InstagramService } from "../../_services/instagram.service";
import { SeoService } from "../../_services/seo.service";
import { InstagramPost } from "../../_models/instagramPost.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NewsletterService} from "../../_services/newsletter.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;

  public subscribeForm: FormGroup;
  public subscribeMessage = '';

  backgroundImages = [
    //'url("assets/Carnevale_degli_animali20.jpg")',
    //'url("assets/Carnevale_degli_animali32.jpg")',
    //'url("assets/Barbiere_di_Siviglia_34.jpg")',
    //'url("assets/DSC03179.jpg")',
    //'url("assets/Barbiere_di_Siviglia_39.jpg")',
    //'url("assets/DSC02968.jpg")',
    //'url("assets/Barbiere di Siviglia_05.jpg")',
    'url("assets/home/Corelli_Star_Wars_Lugo-47.jpg")',
    'url("assets/home/DAN_9483.jpg")',
    'url("assets/home/Melo_Logic-Cervia-11.jpg")',
    'url("assets/home/_ANG3137.jpg")',
    'url("assets/home/DAN_9422.jpg")',
  ];

  config = {
    init: false,
    effect: "fade",
    loop: true,
    loopAdditionalSlides: 2,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    scrollbar: false,
    navigation: false,
    pagination: false
  };
  index: number;

  instagramPosts: InstagramPost[];

  public events: Event[];

  constructor(
      private _instagramService: InstagramService,
      private _seoService: SeoService,
      private _eventService: EventService,
      private _formBuilder: FormBuilder,
      private _newsletterService: NewsletterService
  ) {
    this._instagramService.getAll().subscribe(posts => {
      this.instagramPosts = posts;
    });
    this._eventService.getAll().subscribe(events => {
      this.events = events.slice(0,3);
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.componentRef.directiveRef.init();
    }, 200);

    this._seoService.setPage(
      "Home",
      "La Corelli, Orchestra La Corelli, Ensemble La Corelli, EST, Youbrass Ensemble");

    this.buildForm();
  }

  buildForm() {
    this.subscribeForm = this._formBuilder.group({
      email: ['', Validators.required],
      fullName: [''],
    });
  }

  subscribe() {
    this.subscribeMessage = '';
    const formValue = this.subscribeForm.value;

    if (this.subscribeForm.valid) {
      const formValue = this.subscribeForm.value;
      this._newsletterService.subscribe(formValue.email, formValue.fullName).subscribe((response) => {
        console.log(response);
        if (response.status != "subscribed") {
          this.subscribeMessage = 'Si è verificato un errore! (' + response.title + ')';
        } else {
          this.subscribeMessage = 'Iscrizione effettuata!';
        }
      }, (error) => {
        console.log(error);
        this.subscribeMessage = 'Si è verificato un errore!';
      });
    }
  }
}
