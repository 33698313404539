import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PollService } from './_services/poll.service';

import { Routing } from './app.routing';
import { AppComponent } from './app.component';
import { MelologicComponent } from './content/melologic/melologic.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContentComponent } from './content/content.component';
import { HomeComponent } from './content/home/home.component';
import { PageNotFoundComponent } from './content/page-not-found/page-not-found.component';
import { EventComponent } from './content/event/event.component';
import {EventService} from './_services/event.service';
import {HttpClientModule} from '@angular/common/http';
import { SeoService } from "./_services/seo.service";
import { LacorelliComponent } from './content/lacorelli/lacorelli.component';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { InstagramService } from "./_services/instagram.service";
import { ResponsiveHelperComponent } from './content/responsive-helper/responsive-helper.component';
import { CalendarComponent } from './content/calendar/calendar.component';
import { EnsembleTempoPrimoComponent } from './content/ensemble-tempo-primo/ensemble-tempo-primo.component';
import { EventsComponent } from './content/events/events.component';
import { TagService } from "./_services/tag.service";
import { VenueService } from "./_services/venue.service";
import { AmministrazioneTrasparenteComponent } from './content/amministrazione-trasparente/amministrazione-trasparente.component';
import { OrchestraArcangeloCorelliComponent } from './content/orchestra-arcangelo-corelli/orchestra-arcangelo-corelli.component';
import { PopFusionOrchestraComponent } from './content/pop-fusion-orchestra/pop-fusion-orchestra.component';
import { YoubrassEnsembleComponent } from './content/youbrass-ensemble/youbrass-ensemble.component';
import { EstComponent } from './content/est/est.component';
import { ContactComponent } from './content/contact/contact.component';
import {NewsletterService} from "./_services/newsletter.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { PolicyComponent } from './content/policy/policy.component';
import {CookieService} from "./_services/cookie.service";
import { CookieBannerComponent } from './content/cookie-banner/cookie-banner.component';
import {NgxJsonLdModule} from "@ngx-lite/json-ld";
import { TrasparenzaComponent } from './content/trasparenza/trasparenza.component';
import {DelittoComponent} from "./content/delitto/delitto.component";
import {AnswerService} from "./_services/answer.service";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContentComponent,
    HomeComponent,
    PageNotFoundComponent,
    EventComponent,
    LacorelliComponent,
    ResponsiveHelperComponent,
    CalendarComponent,
    EnsembleTempoPrimoComponent,
    EventsComponent,
    AmministrazioneTrasparenteComponent,
    OrchestraArcangeloCorelliComponent,
    PopFusionOrchestraComponent,
    YoubrassEnsembleComponent,
    EstComponent,
    ContactComponent,
    PolicyComponent,
    CookieBannerComponent,
    TrasparenzaComponent,
    MelologicComponent,
    DelittoComponent,
  ],
  imports: [
    BrowserModule,
    Routing,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    SwiperModule,
    NgxJsonLdModule

  ],
  providers: [
    EventService,
    TagService,
    VenueService,
    SeoService,
    InstagramService,
    NewsletterService,
    CookieService,
    PollService,
    AnswerService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
