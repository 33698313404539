import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EventService } from "../../_services/event.service";
import { SeoService } from "../../_services/seo.service";
import { Event } from "../../_models/event.model";

import { Control, DomUtil, LatLng, Map, Marker, TileLayer } from "leaflet";

declare const L;


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.sass']
})
export class EventComponent implements OnInit {

  @ViewChild('mapContainer', {static: false}) mapContainer: ElementRef;

  public event: Event;

  private _map: Map;
  private _marker: Marker;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _eventService: EventService,
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._seoService.setPage("Evento", "La Corelli evento");

    this._activatedRoute.params.subscribe(params => {
      if (params['uuid']) {
        this._eventService.get(params['uuid'])
          .subscribe((event: Event) => {
            this.event = event;
            this.eventReady();
          }, () => {
            this._router.navigate(['/not-found']);
          });
      } else {
        this._router.navigate(['/not-found']);
      }
    });
  }

  public eventReady() {
    this._seoService.setPage(this.event.title, this.event.subtitle);

    if (this.event.venue) {
      setTimeout(() => {
        this._map = L.map(this.mapContainer.nativeElement, {
          center: [this.event.venue.coords.lat, this.event.venue.coords.lng],
          zoom: 10
        });

        new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this._map);

        const defaultMarker = L.icon({
          iconUrl:       '/assets/marker-icon.png',
          iconRetinaUrl: '/assets/marker-icon-2x.png',
          shadowUrl:     '/assets/marker-shadow.png',
          iconSize:    [25, 41],
          iconAnchor:  [12, 41],
          popupAnchor: [1, -34],
          tooltipAnchor: [16, -28],
          shadowSize:  [41, 41]
        });

        this._marker = new Marker([this.event.venue.coords.lat, this.event.venue.coords.lng], {
          draggable: false,
          icon: defaultMarker
        });
        this._marker.addTo(this._map);
      }, 1000);
    }
  }

}
